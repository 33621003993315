<template>
    <div class="wrapper landing-page">
        <div class="page-header">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner.jpg')">
            </parallax>
            <div id="" class=""
                style="background-image: url('img/logo.png');">
            </div>
            <div class="content-center">
                <h1 class="title">Burnley Sales & Lettings</h1>
            </div>
        </div>

        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Who we are?</h2>
                        <h5>Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being
                          dissatisfied with the service we were receiving,
                          felt it was time to make a difference. <a href="/about-us">Read More</a></h5>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
            </div>
        </div>

        <div class="page-header header-filter">
            <div id="" class="page-header-image section-image"
                style="background-image: url('img/section2.jpg');">
            </div>
            <div class="content-center">
                <div class="container">
                    <div class="content-center">
                        <div class="row">
                            <div class="col-md-6 mr-auto text-left">
                                <h2 class="title">Are you a Tenant or Landlord?</h2>
                                <h5>Find out vital information for Tenant or Landlords wishing to utilise our services
                                </h5>
                                <br/>
                                <div class="buttons">
                                    <a href="/tenants" class="btn btn-neutral btn-primary btn-lg mr-1">
                                        Tenants
                                    </a>
                                    <a href="/landlords" class="btn btn-secondary btn-lg">
                                        Landlords
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="propertyList.length === 0" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">No Content at the moment</h2>
                        <h5>Please check back with us another time</h5>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="propertyList.length !== 0" class="section cards">
            <div class="container">
                <div class="title">
                    <h2 class="title text-center">
                        Our Latest Properties
                    </h2>
                </div>
                <div class="row">
                    <!-- |||||||||||||||||||||||||||| -->
                    <li v-for="property in propertyList"  :key="property.id" class="col-md-4" style="display: inline;">
                        <div class=""> <!-- v-if="pagination.simple === 2" -->
                            <router-link class="text-white" :to="{ name: 'property', params: {property_id: property.id} }">
                                <card type="background" :style="{ backgroundImage: 'url(' + property.images[0] + ')' }">
                                    <div class="card-title text-left">
                                        <h4 class="card-title text-white">
                                            <i class="now-ui-icons location_pin"/> {{ property.display }}
                                        </h4>
                                    </div>
                                    <div class="card-footer text-left">
                                        <div class="stats">
                                            <h5>{{property.bedrooms}} Bedroom {{property.type}}</h5>
                                        </div>
                                        <div v-if="property.qualifier.includes('Offers')" class="stats pull-right">
                                            <h3> <!-- {{ property.status }} | -->
                                                {{ property.status }} | {{ property.qualifier }} &#163; {{ property.price }}
                                            </h3>
                                        </div>
                                        <div v-else class="stats pull-right">
                                            <h3>
                                                {{ property.status }} | &#163; {{ property.price }} {{ property.qualifier }}
                                            </h3>
                                        </div>
                                    </div>
                                </card>
                            </router-link>
                            <router-view :key="$route.id"></router-view>
                        </div>
                    </li>
                </div>
                <a href="/all-properties">
                    <h4>See more</h4>
                </a>
            </div>
        </div>

        <div class="page-header header-filter">
            <div class="page-header-image section-image"
                style="background-image: url('img/section1.jpg')">
            </div>
            <div class="content-center">
                <div class="container">
                    <div class="content-center">
                        <div class="row">
                            <div class="col-md-8 ml-auto text-right">
                                <h2 class="title">Buying and Selling<br/>Made Easy</h2>
                                <h5>If you are looking to buy and sell in the Burnley area, we will
                                    guide you through the process
                                </h5>
                                <br/>
                                <div class="buttons">
                                    <a href="/buyers" class="btn btn-neutral btn-primary btn-lg mr-1">
                                        Buying Guide
                                    </a>
                                    <a href="/sellers" class="btn btn-secondary btn-lg mr-1">
                                        Selling Guide
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    props: { propertyList: Array },
    metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.burnleysl.co.uk/'},
                {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
                {property: 'og:description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                // Google / Schema.org markup:
                {itemprop: 'description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.burnleysl.co.uk/'}
            ]
        }
    },
    methods: {
        getImg: function (postImg) {
            if (!postImg) return require("../../public/img/image_placeholder.jpg") // test with actual images stored in a db
            else return postImg // https://i.ibb.co/r5hp1ng/spash-img.jpg or require(postImg)
        }
    },
    mounted () {
        //console.log(this.propertyList)
    }
  }
</script>
<style>
.text-white {
    color: #fff;
}
</style>
