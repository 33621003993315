<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner3.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Thinking of Selling?</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Selling With Us: The Process</h2>
                        <h5>
                            1 – Call us to arrange a free, no-obligation valuation of your property and discuss the requirements for your move.  We will use historic sales figures and current market data to provide an accurate valuation, ensuring you receive the best price in a suitable timeframe.
                            <br/>
                            <br/>
                            <br/>
                            2 – Once we have agreed on a valuation, we will take professional quality photographs, create detailed property particulars and erect an eye-catching ‘FOR SALE’ board to ensure your property looks at its best for potential buyers.
                            <br/>
                            <br/>
                            <br/>
                            3 – After confirming all the details with you, we will begin to market the property both locally and nationally, using a range of traditional and modern methods and keep you fully updated on how the market is reacting to your property.
                            <br/>
                            <br/>
                            <br/>
                            4 – Shortly after marketing begins, we will begin to receive enquires about your property. We will qualify each potential purchaser to ensure that they are able to buy and that your property is a perfect match for them before arranging an accompanied viewing.  This ensures that the right buyer comes through your door to help save your time.
                            <br/>
                            <br/>
                            <br/>
                            5 – We will then receive offers on your property and will negotiate the best possible price for you – removing the stress of any haggling.  As soon as a sale is agreed we will complete a memorandum of sale and send it to your appointed conveyancer.  To make things even easier we can refer you to one of our trusted conveyancers.
                            <br/>
                            <br/>
                            <br/>
                            6 – As your sale progresses, we will ensure that we do all the leg work by chasing up all parties and provide updates along the way - again leaving you hassle and stress-free.
                            <br/>
                            <br/>
                            <br/>
                            7 – When all the legal work is finished and completion has taken place, simply leave the keys with us and we will arrange to meet with your buyer to hand them over!
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="subscribe-line subscribe-line-image" style="background-image: url('img/mini-banner.png'); height: 252px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h3 class="title">Now that you know our process</h3>
                        <h4 class="title">Call us on 01282 476732 to arrange your no-obligation valuation!</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
  }
</script>
