<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner6.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">About Us</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                      <h2 class="title">Introduction</h2>
                      <h5 id="about-us">
                          Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being
                          dissatisfied with the service we were receiving, felt it was time to make a difference.
                          <br/>
                          <br/>
                          We believe that excellent service should be provided at a fair price and that building and maintaining
                          long-term relationships is more important than making a quick profit. 
                          <br/>
                          <br/>
                          Our aim is to provide an excellent service that gives good value for money, whilst ensuring that we are
                          working for the best interests of our customers.
                          <br/>
                          <br/>
                          Our team of experienced housing specialists have over 60 years of combined experience in property –
                          in both Sales & Lettings – and are always on hand to guide you through your property journey.
                      </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';
  import {Table, TableColumn, Tooltip} from 'element-ui';

  export default {
    // name: 'landing-page',
    // bodyClass: 'landing-page',
    components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    }
  }
</script>
<style>
.tab {
  padding-left: 30px;
}
.link-pad {
  padding-top: 40px;
}
</style>
