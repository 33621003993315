<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image pad"
                 style="background-image: url('img/banner4.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Buyers</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Buying With Us: The Process</h2>
                        <h5>
                            1 – Call us to discuss your property aspirations and what you require from your next home.  We can even help you navigate the tricky mortgage market - via one of our whole-of-market, regulated and compliant partner companies.
                            <br/>
                            <br/>
                            <br/>
                            2 – Once we understand your property needs, we will collate a list of potential properties that match your requirements and discuss them with you in further detail.  We can even inform you of properties before they come on the market, allowing you to see your next home before anyone else.
                            <br/>
                            <br/>
                            <br/>
                            3 – We will then arrange an accompanied viewing for your perfect property so you can picture getting the keys and moving in.
                            <br/>
                            <br/>
                            <br/>
                            4 – Once you are confident you have found your next home, call us to put forward your offer.  We will negotiate between yourself and the owner to take away any stress.
                            <br/>
                            <br/>
                            <br/>
                            5 - As soon as a sale is agreed, we will complete a memorandum of sale and send it to your appointed conveyancer - To make it even easier we can refer you to one of our trusted conveyancers to guarantee you are cared for.
                            <br/>
                            <br/>
                            <br/>
                            6 – We will keep in touch with all parties and the conveyancer to ensure a smooth process and keep you fully updated throughout – removing all the stress and hassle.
                            <br/>
                            <br/>
                            <br/>
                            7 – When all the legal work is finished and completion has taken place, we will make sure keys are available for you to collect so you can move into your new home.
                        </h5>
                    </div>
                </div>
                <!--TopArrow></TopArrow-->
            </div>
        </div>

        <div class="subscribe-line subscribe-line-image" style="background-image: url('img/mini-banner.png'); height: 252px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h3 class="title">Now that you know our process</h3>
                        <h4 class="title">Call us now on 01282 476732 to discuss your property aspirations so we can help you find your next home</h4> <!--or view our available properties</h4>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import TopArrow from './TopArrow'

  export default {
    components: {
      TopArrow
    }
  }
</script>
<style>
  .pad {
    margin-top: 50px;
  }
</style>
<style scoped>
  .tes {
    padding-top: -20px;
  }
</style>
