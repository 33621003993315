<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner10.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Maintenance Team</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <!--h2 class="title">Maintenance Team</h2-->
                        <h5>
                            Our experienced and helpful maintenance team cover all aspects of Building Maintenance & Repair.
                            From changing a washer, to fitting a bath, to full property refurbishment - we can do it all!
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
  }
</script>
