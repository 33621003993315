import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import VueMeta from 'vue-meta'
import axios from 'axios'
import { CarouselPlugin } from 'bootstrap-vue'




//import Vue from 'vue';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);



//plugins
import NowUIKit from './plugins/now-ui-kit';

Vue.prototype.$http = axios

Vue.config.productionTip = false;
Vue.use(NowUIKit);
Vue.use(VueMeta);
Vue.use(CarouselPlugin);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
