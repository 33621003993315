var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper landing-page"},[_c('div',{staticClass:"page-header"},[_c('parallax',{staticClass:"page-header-image",staticStyle:{"background-image":"url('img/banner.jpg')"}}),_c('div',{staticStyle:{"background-image":"url('img/logo.png')"},attrs:{"id":""}}),_vm._m(0)],1),_vm._m(1),_vm._m(2),(_vm.propertyList.length === 0)?_c('div',{staticClass:"section"},[_vm._m(3)]):_vm._e(),(_vm.propertyList.length !== 0)?_c('div',{staticClass:"section cards"},[_c('div',{staticClass:"container"},[_vm._m(4),_c('div',{staticClass:"row"},_vm._l((_vm.propertyList),function(property){return _c('li',{key:property.id,staticClass:"col-md-4",staticStyle:{"display":"inline"}},[_c('div',{},[_c('router-link',{staticClass:"text-white",attrs:{"to":{ name: 'property', params: {property_id: property.id} }}},[_c('card',{style:({ backgroundImage: 'url(' + property.images[0] + ')' }),attrs:{"type":"background"}},[_c('div',{staticClass:"card-title text-left"},[_c('h4',{staticClass:"card-title text-white"},[_c('i',{staticClass:"now-ui-icons location_pin"}),_vm._v(" "+_vm._s(property.display)+" ")])]),_c('div',{staticClass:"card-footer text-left"},[_c('div',{staticClass:"stats"},[_c('h5',[_vm._v(_vm._s(property.bedrooms)+" Bedroom "+_vm._s(property.type))])]),(property.qualifier.includes('Offers'))?_c('div',{staticClass:"stats pull-right"},[_c('h3',[_vm._v(" "+_vm._s(property.status)+" | "+_vm._s(property.qualifier)+" £ "+_vm._s(property.price)+" ")])]):_c('div',{staticClass:"stats pull-right"},[_c('h3',[_vm._v(" "+_vm._s(property.status)+" | £ "+_vm._s(property.price)+" "+_vm._s(property.qualifier)+" ")])])])])],1),_c('router-view',{key:_vm.$route.id})],1)])}),0),_vm._m(5)])]):_vm._e(),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-center"},[_c('h1',{staticClass:"title"},[_vm._v("Burnley Sales & Lettings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto mr-auto text-center"},[_c('h2',{staticClass:"title"},[_vm._v("Who we are?")]),_c('h5',[_vm._v("Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference. "),_c('a',{attrs:{"href":"/about-us"}},[_vm._v("Read More")])])])]),_c('div',{staticClass:"separator separator-primary"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header header-filter"},[_c('div',{staticClass:"page-header-image section-image",staticStyle:{"background-image":"url('img/section2.jpg')"},attrs:{"id":""}}),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mr-auto text-left"},[_c('h2',{staticClass:"title"},[_vm._v("Are you a Tenant or Landlord?")]),_c('h5',[_vm._v("Find out vital information for Tenant or Landlords wishing to utilise our services ")]),_c('br'),_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"btn btn-neutral btn-primary btn-lg mr-1",attrs:{"href":"/tenants"}},[_vm._v(" Tenants ")]),_c('a',{staticClass:"btn btn-secondary btn-lg",attrs:{"href":"/landlords"}},[_vm._v(" Landlords ")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto mr-auto text-center"},[_c('h2',{staticClass:"title"},[_vm._v("No Content at the moment")]),_c('h5',[_vm._v("Please check back with us another time")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h2',{staticClass:"title text-center"},[_vm._v(" Our Latest Properties ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/all-properties"}},[_c('h4',[_vm._v("See more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header header-filter"},[_c('div',{staticClass:"page-header-image section-image",staticStyle:{"background-image":"url('img/section1.jpg')"}}),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto text-right"},[_c('h2',{staticClass:"title"},[_vm._v("Buying and Selling"),_c('br'),_vm._v("Made Easy")]),_c('h5',[_vm._v("If you are looking to buy and sell in the Burnley area, we will guide you through the process ")]),_c('br'),_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"btn btn-neutral btn-primary btn-lg mr-1",attrs:{"href":"/buyers"}},[_vm._v(" Buying Guide ")]),_c('a',{staticClass:"btn btn-secondary btn-lg mr-1",attrs:{"href":"/sellers"}},[_vm._v(" Selling Guide ")])])])])])])])])
}]

export { render, staticRenderFns }