<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner7.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Privacy Policy</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <navbar position="fixed" menu-classes="ml-auto">
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item active">
                              <a class="nav-link" href="#privacy-policy">Privacy Policy</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#info-collection">Information we may collect about you</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#personal-info">How we use information about you</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#data-sharing">Who we might share your data with</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#data-sharing-categories">Data categories and who they are shared with</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#third-party-contrib">Third Party Content, Sites, and Contributions</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#data-storage">How we look after your data and how long we keep it for</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#storage-location">Where we store your information</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link tab" href="#data-rights">Your rights in relation to your Personal Data</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#ownership">Changes of Business Ownership and Control</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#policy-changes">Changes to this Privacy Policy</a>
                            </li>
                          </ul>
                        </div>
                      </navbar>
                  </div>
                    <div class="col-md-9 ml-auto mr-auto">
                      <div id="privacy-policy" class="link-pad">
                        <h5>
                          <em>Policy updated February 2024</em><br/><br/>
                          This Policy applies as between you, the User of this Web Site and Burnley Sales & Lettings LTD (BSL)
                          the owner and provider of this Web Site. This Policy applies to our use of any and all Data collected by
                          us in relation to your use of the Web Site and any Services or Systems therein.
                          <br/>
                          
                          <br/>
                        </h5>

                        <h3 class="title">Introduction</h3>
                        <h5>
                          This privacy policy (Privacy Policy) sets out the ways in which Burnley Sales & Lettings LTD (BSL)
                          may use your personal data.<br/><br/>
                          In this Privacy Policy:
                          <br/>
                          <ul>
                            <li>‘we’, ‘us’ or ‘our’ means Burnley Sales & Lettings LTD (BSL)</li>
                            <li>‘the site’ means Burnley Sales & Lettings LTD (BSL) website you are visiting or engaging with; and</li>
                            <li>‘you’ and ‘your’ means you, the person engaging with us, registering for our services or visiting our site.</li>
                          </ul>
                          By subscribing to our services, visiting our site, or otherwise interacting with us, we will process your personal data
                          in accordance with this Privacy Policy.
                          <br/>
                          <br/>
                          How you can contact us (The Data Controller):<br/>
                          Address: <b>78 Coal Clough Lane, Burnley, Lancashire, BB11 4NW</b>
                          <br/>
                          ICO Registration Number: <b>ZA865430</b>
                          <br/>
                          Email: <a href="mailto:lettings@burnleysl.co.uk"><b>lettings@burnleysl.co.uk</b></a>
                          <br/>
                          Telephone: <b>01282 476 732</b>
                          <br/>
                          <br/>
                        </h5>
                      </div>

                      <div id="info-collection" class="link-pad">
                        <h3 class="title">Information we may collect about you</h3>
                        <h5>
                          We use different ways to collect data about you including the information you
                          supply to us when using our services. If you fail to provide certain information,
                          we may not be able to provide you with the service you require. The data
                          gathered depends on whether you are an applicant wanting to just view a
                          property, a tenant taking a property or a landlord advertising a property. We
                          will also hold differing amounts of data depending on whether we manage the
                          property for the landlord or not.
                          <br/>
                          <br/>
                          As necessary personal data is processed by us, we retain the following data as
                          applicable: –<br/>
                          <ul>
                            <li>Name, email and telephone numbers</li>
                            <li>Personal/background information including occupation/status</li>
                            <li>Bank details</li>
                            <li>Verification and credit status</li>
                            <li>Right to Rent ID such as copies of passports, driving licenses and residency permits</li>
                            <li>Deposit (if any) including return on tenancy termination</li>
                            <li>Tenancy details including renewals, joint tenants, other residents and guarantors</li>
                            <li>Immigration/right to rent checks (England only)</li>
                            <li>Rent and other payments</li>
                            <li>Recovery of arrears, claims or possession proceedings</li>
                            <li>Repairs/health and safety/housing conditions</li>
                            <li>Breach of tenancy terms/nuisance/anti social behaviour</li>
                            <li>Council Tax liability</li>
                            <li>Water charges payable</li>
                            <li>Utilities and services provided</li>
                            <li>Welfare Benefits</li>
                            <li>Termination of tenancy</li>
                            <li>Audio and CCTV recordings (if any)</li>
                            <li>Complaints</li>
                            <li>Insurance</li>
                            <li>Health or disability</li>
                            <li>Emails texts and other communications and via our website where we operate one</li>
                            <li>Website and online portal information</li>
                            <li>Identity and contact details including car registration</li>
                            <li>We also generate and use data internally, e.g. our rent records if managing the property</li>
                          </ul>
                          <br/>
                          <br/>
                          We also collect and receive data about you from third parties. This may be
                          information given to us by the landlord, other tenants or residents or
                          neighbours. It can include information from a guarantor where there is a
                          guarantor for your tenancy or from a joint tenant or other residents. Public
                          bodies such as local authorities or the police, or other law enforcement
                          agencies may give us information about you. This can include the Department
                          for Work and Pensions or the local authority where you are receiving Universal
                          Credit or housing benefit. Information may be given to us relevant to Council
                          Tax by the local authority. Utility companies or service providers may also give
                          us personal information about you. We obtain information about you when we
                          carry out credit checks or take up references. We may also receive information
                          from you via websites or from online rental portals such as Rightmove, Zoopla
                          or Gumtree for example.
                        </h5>
                      </div>

                      <div id="personal-info" class="link-pad">
                        <h3 class="title">How we use information about you</h3>
                        <h5>
                          The various purposes for which it may be necessary for us to process different
                          categories of your information include: –<br/>
                          <ul>
                            <li>In our legitimate interests for ensure you are allowed to advertise or rent out the property</li>
                            <li>In our legitimate interests for deciding on the suitability of a proposed tenant/resident</li>
                            <li>In our legitimate interests for verifying the credit worthiness/suitability of tenants/residents</li>
                            <li>Our legal obligation to check immigration status/right to rent. This is also to verify identities.</li>
                            <li>Our legal obligation with regards to Anti-Money Laundering regulations</li>
                            <li>To perform our tenancy contract to deal with joint tenants and residents who are linked to the tenancy</li>
                            <li>To perform our contract to complete the tenancy agreement</li>
                            <li>In our legitimate interests to secure rental payments/performance of tenant obligations, e.g. deposits and guarantors</li>
                            <li>For contractual performance for rent collection and collection of other payments including banking details</li>
                            <li>For contractual performance for managing the tenancy and the property</li>
                            <li>For contractual performance and/or in our legitimate interests for record keeping</li>
                            <li>For contractual performance for arranging repairs and maintaining the condition of the property and keeping it in a safe condition.</li>
                            <li>For contractual performance for monitoring and enforcement of tenant responsibilities</li>
                            <li>For contractual performance or in our legitimate interests for recovering debts and other payments due, including any possession proceedings</li>
                            <li>In our legitimate interests for administering liability for Council Tax</li>
                            <li>In our legitimate interests and those of the provider relating to arranging and paying for utilities and services</li>
                            <li>In our legitimate interests for dealing with welfare benefits (including Universal Credit and housing benefit) where payable in respect of the rent</li>
                            <li>In our legitimate interests in relation to tenancy termination including the return of any deposit</li>
                            <li>In our legitimate interests for processing complaints</li>
                            <li>For contractual performance or in our legitimate interests for dealing with health and disability issues relating to tenants/residents</li>
                            <li>In our legitimate interests for obtaining and holding audio and cctv recordings</li>
                            <li>To perform our legal obligations to provide information to public or local authorities who are legally entitled to require this information</li>
                            <li>In your vital interests for contacting next of kin etc., in an emergency</li>
                            <li>In our legitimate interests for the storage of emails, records of calls and other communications</li>
                            <li>In accordance with our legal obligations if you exercise your rights under data protection law</li>
                            <li>To perform our legal obligations for compliance with legal and regulatory requirements</li>
                            <li>In our legitimate interests for the establishment and defence of legal rights</li>
                            <li>In our legitimate interests for prevention, detection and investigation of crime and anti social behaviour and the security of any website or other means of electronic communication</li>
                            <li>Upon paying us for our services you agree that we shall have exclusive ownership and control of any data provided and/or used in conjunction with the delivery of the services.</li>
                          </ul>
                          <br/>
                          <br/>
                          We may change the purposes where this is compatible for the purpose for
                          which we obtained the data originally. If we need to use your data for a noncompatible purpose we will notify you and explain the legal gateway that
                          allows us to do so. We may process your information without your knowledge
                          where this is required or permitted by law.
                        </h5>
                      </div>

                      <div id="data-sharing" class="link-pad">
                        <h3 class="title">Who we might share your data with</h3>
                        <h5>
                          We never sell your data.
                          <br/>
                          <br/>
                          In certain circumstances we may share your personal information with:<br/>
                          Selected third parties that we work with, where necessary for the purposes of
                          delivering to you services that you request from us. For example, when you are
                          reference checked, we need to share your information with a third party
                          referencing sub-contractor. Where we use such third parties, those third
                          parties are obligated to protect your data in accordance with the prevailing
                          General Data Protection Regulations.
                          <br/>
                          <br/>
                          Selected third parties that you consent to our sharing your information with
                          for marketing purposes (such consent will be sought prior to our sharing of this
                          data).
                          <br/>
                          <br/>
                          Any other third parties where necessary to enable us to enforce our legal
                          rights, or to protect the rights, property or safety of our employees or where
                          such disclosure may be permitted or required by law.
                        </h5>

                        <h4 class="title">Landlords</h4>
                        <h5>
                          For the purposes of delivering to you services that you request from us, we must record
                          your data on our property management system. Your data (name, email address, telephone number,
                          property details, photos, etc) may also be provided to other third party companies in order that
                          we can provide you with the service you have requested from us, for example our reference provider,
                          so that they can provide you with reference reports, and other information that is relevant to you;
                          inventory clerks; gas engineers; EPC assessors etc. If we’re managing the property for you, we’ll also
                          require additional information such as ID and bank details (for rent payments).
                          <br/>
                          <br/>
                        </h5>

                        <h4 class="title">Tenants in Non-Managed Properties</h4>
                        <h5>
                            For the purposes of delivering to you services that you request from us, we
                            must record your data on our property management system. Your data will be
                            provided to landlords in order that they can contact you to arrange your
                            viewing. Your data (name, email address, telephone number) may also be
                            provided to other third party companies in order that we can provide you with
                            the service you have requested from us, for example our reference provider,
                            so that they can complete your reference report which will also be provided to
                            your prospective landlord.
                        </h5>

                        <h4 class="title">Tenants in Managed Properties</h4>
                        <h5>
                            As well as the details mentioned above, we may provide your data to utility
                            companies and the council in order that you can billed directly for services
                            provided. It may also be necessary, from time to time, to provide your data to 
                            contractors who may attend the property for the purpose of carrying out
                            inspections, safety certificates, maintenance work etc.<br/><br/>
                            We may also be requested to share your data with other government
                            departments such as HMRC or Benefit Offices as well as 3rd party companies
                            such as Rent Guarantee Insurers, Property Free Holders and Deposit Lodging
                            Agencies.
                        </h5>
                      </div>
                      
                      <div id="data-sharing-categories" class="link-pad">
                        <h3 class="title">Data categories and who they are shared with</h3>
                        <div class="ml-auto mr-auto">
                          <card class="card-plain">
                              <div class="row">
                                  <div class="col-sm-12">
                                      <el-table stripe :data="tableData">
                                          <el-table-column min-width="150"
                                                            align="left"
                                                            prop="category"
                                                            label="Data category">
                                          </el-table-column>
                                          <el-table-column min-width="200"
                                                            prop="details"
                                                            align="left"
                                                            label="With whom we share the data">
                                          </el-table-column>
                                      </el-table>
                                  </div>
                              </div>
                          </card>
                        </div>

                        <h4 class="title">Private persons/organisations/public authorities with whom any information is shared</h4>
                        <h5>
                          As necessary, we share all of your information (irrespective of its category) with certain private
                          bodies/organisations/public authorities. This includes transferring your information to them and
                          receiving it from them. These are:
                        </h5>

                        <h4 class="title">Private persons/organisations</h4>
                        <div class="ml-auto mr-auto">
                          <card class="card-plain">
                              <div class="row">
                                  <div class="col-sm-12">
                                      <el-table stripe :data="privateOrg">
                                          <el-table-column min-width="150"
                                                            align="left"
                                                            prop="category"
                                                            label="Categories of persons/organisations">
                                          </el-table-column>
                                          <el-table-column min-width="200"
                                                            prop="details"
                                                            align="left"
                                                            label="Purpose and legal gateway">
                                          </el-table-column>
                                      </el-table>
                                  </div>
                              </div>
                          </card>
                        </div>

                        <h4 class="title">Public Authorities</h4>
                        <div class="ml-auto mr-auto">
                          <card class="card-plain">
                              <div class="row">
                                  <div class="col-sm-12">
                                      <el-table stripe :data="publicAuthorities">
                                          <el-table-column min-width="150"
                                                            align="left"
                                                            prop="category"
                                                            label="Categories of persons/organisations">
                                          </el-table-column>
                                          <el-table-column min-width="200"
                                                            prop="details"
                                                            align="left"
                                                            label="Purpose and legal gateway">
                                          </el-table-column>
                                      </el-table>
                                  </div>
                              </div>
                          </card>
                        </div>

                        <h4 class="title">Others</h4>
                        <div class="ml-auto mr-auto">
                          <card class="card-plain">
                              <div class="row">
                                  <div class="col-sm-12">
                                      <el-table stripe :data="otherData">
                                          <el-table-column min-width="150"
                                                            align="left"
                                                            prop="category"
                                                            label="Category of person/organisation/public authority">
                                          </el-table-column>
                                          <el-table-column min-width="200"
                                                            prop="details"
                                                            align="left"
                                                            label="Purposes and legal gateway">
                                          </el-table-column>
                                      </el-table>
                                  </div>
                              </div>
                          </card>
                        </div>
                      </div>

                      <div id="third-party-contrib" class="link-pad">
                        <h2 class="title">Third Party Content, Sites, and Contributions</h2>
                        <h5>
                          Our website may contain content and links to other websites or apps that are
                          operated by third parties. We don’t control these third party websites or apps
                          (including whether or not they store cookies) and this Privacy Policy does not
                          apply to them. Please consult the terms and conditions and Privacy Policy of
                          the relevant third party website or app to find out how that site/app collects
                          and uses your information and to establish whether and for what purpose they
                          use cookies.
                        </h5>
                      </div>

                      <div id="data-storage" class="link-pad">
                        <h2 class="title">How we look after your data and how long we keep it for</h2>
                        <h5>
                          We will retain your information for as long as is necessary to provide you with
                          the services that you have requested from us, or for as long as the law
                          otherwise permits.<br/><br/>
                          Transmission of information over the internet can be insecure, and although
                          we employ measures to protect your information from unauthorised access we
                          cannot always guarantee the security of information sent over the internet.<br/><br/>
                          We will hold personal data about tenants for the duration of your tenancy and
                          for seven years after your tenancy has ended. This is the statutory limitation
                          period six years plus a further year to allow for service of proceedings should
                          proceedings commence later.
                        </h5>
                      </div>

                      <div id="storage-location" class="link-pad">
                        <h3 class="title">Where we store your information</h3>
                        <h5>
                          Our servers are situated inside the United Kingdom and most of the
                          information that we collect about you will be stored on these servers.
                          <br/><br/>
                          Some providers store related data internationally and not necessarily within
                          the United Kingdom. The recipient of this data is the provider concerned. You
                          need to refer to the provider concerned to determine if they have the required
                          clearance (adequacy decision) from the United Kingdom authorities or whether
                          or not, instead, there is an agreement containing appropriate and suitable
                          safeguards and to obtain a copy of this agreement.
                        </h5>
                      </div>

                      <div id="data-rights" class="link-pad">
                        <h3 class="title">Your rights in relation to your Personal Data</h3>
                        <h5>
                          You have the right to:<br/>
                          Request a copy of the personal data that we keep about you, by making a
                          request in writing to the Data Protection Officer. Our Data Protection Officers
                          details are provided above. You can ask us to provide the data in a commonly
                          used, machine readable format, and where we are able to; transmit this data
                          to another organisation.<br/><br/>
                          Contact the Data Protection Officer if you are concerned that any of the
                          information we hold on you is incorrect, to have that data corrected.<br/><br/>
                          Contact the Data Protection Officer to ask us to stop processing your data, or
                          withdraw consent where we are relying on consent as the legal basis for any
                          processing of your data.<br/><br/>
                          Contact the Data Protection Officer to request that we delete your personal
                          data.
                        </h5>
                      </div>

                      <div id="ownership" class="link-pad">
                        <h2 class="title">Changes of Business Ownership and Control</h2>
                        <h5>
                          Burnley Sales & Lettings LTD (BSL) may expand or reduce its business and this
                          may involve the sale of certain divisions or the transfer of control of certain
                          divisions to other parties. Data provided by you will, where it is relevant to any
                          division so transferred, be transferred along with that division and the new
                          owner or newly controlling party will, under the terms of this Policy, be
                          permitted to use the Data for the purposes for which it was supplied by you.
                          <br/><br/>
                          In the event that any Data submitted by you will be transferred in such a
                          manner, you will not be contacted in advance and informed of the changes.
                        </h5>
                      </div>

                      <div id="policy-changes" class="link-pad">
                        <h2 class="title">Changes to this Privacy Policy</h2>
                        <h5>
                          Burnley Sales & Lettings LTD (BSL) reserves the right to change this Privacy
                          Policy as we may deem necessary from time to time or as may be required by
                          law. Any changes will be immediately posted on the website and you are
                          deemed to have accepted the terms of the Policy on your first use of the
                          website following the alterations.<br/><br/>
                        </h5>

                        <h3 class="title">Complaints</h3>
                        <h5>
                          We operate our own internal complaints policy and if you have any concerns
                          about the way in which we collect or handle data please contact us.<br/><br/>
                          Additionally, you have the right to lodge a complaint with the Supervisory
                          Authority who is:<br/>
                          <b>Information Commissioner<br/>
                          Information Commissioner’s Office<br/>
                          Wycliffe House<br/>
                          Water Lane<br/>
                          Wilmslow<br/>
                          Cheshire<br/>
                          SK9 5AF<br/>
                          <a href="https://ico.org.uk">www.ico.org.uk</a></b>
                        </h5>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <to-the-top></to-the-top>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';
  import {Table, TableColumn, Tooltip} from 'element-ui';
  // import { ToTheTop } from './components'

  export default {
    // name: 'landing-page',
    // bodyClass: 'landing-page',
    components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        tableData: [{
            category: 'Personal/background information',
            details: 'Other landlords/employers; debt collectors/tracing agents; next of kin etc.; insurers; banks etc.'
        }, {
            category: 'Bank details',
            details: 'Our bank, credit reference agency; debt collects/tracing agents'
        }, {
            category: 'Tenancy details',
            details: 'The landlord, other landlords/employers, benefit authority, taxation authority'
        }, {
            category: 'Deposits (if any)',
            details: 'Tenancy deposit body; debt collectors/tracing agents; taxation authority'
        },  {
            category: 'Immigration/right to rent checks (England only) and verification of tenant’s identity',
            details: 'Home Office; credit reference agency (for verification of identity)'
        },  {
            category: 'Rent and other payments',
            details: 'The landlord, Bank, benefits authority, taxation authority, joint tenants/resident’s guarantor, insurers, other landlords'
        },  {
            category: 'Recovery of arrears, claims and possession',
            details: 'Debt collectors/tracing agents, other landlords, taxation authority, joint tenants/residents, guarantors; insurers'
        },  {
            category: 'Repairs/housing conditions/health and safety',
            details: 'Contractors, tradespeople, etc., deposit protection body, joint tenants/residents, freeholder flat management etc., guarantors'
        },  {
            category: 'Breach of tenancy agreement/nuisance etc',
            details: 'The landlord, Contractors, tradespeople etc., deposit protection body, joint tenants/residents, guarantors, educational institution, neighbours, other residents etc., freeholder, flat management agents etc., insurers'
        },  {
            category: 'Council Tax liability',
            details: 'Taxation authority (local authority) and tenancy assistance companies'
        },  {
            category: 'Water charges',
            details: 'Welsh Water/Dee Valley Water Company (where property is located within the Welsh Water area), other water companies and tenancy assistance companies'
        },  {
            category: 'Utilities and services',
            details: 'Utility suppliers and service providers, e.g. Broadband and tenancy assistance companies'
        },  {
            category: 'Universal Credit, housing benefit etc.',
            details: 'Benefit authority (Department for Work and Pensions or local authority), regulatory authority, joint tenants/residents/guarantors'
        },  {
            category: 'Termination of tenancy',
            details: 'The landlord, Other landlords, educational institutions, debt collectors/tracing agent, taxation authority, deposit protection body, bank joint tenant/resident/guarantors, freeholder managing agents etc.'
        },  {
            category: 'CCTV/audio recording',
            details: 'Deposit body, joint tenants/residents, guarantor, next of kin etc.'
        },  {
            category: 'Correspondence etc.',
            details: 'Depending upon the applicable category of information relevant correspondence etc. is shared with any of the persons/organisations/authorities listed in Section A.'
        },  {
            category: 'Property details & images',
            details: 'Property platforms, potential tenants'
        },  {
            category: 'Online platforms',
            details: 'Joint tenants/residents; guarantors'
        },  {
            category: 'Insurance',
            details: 'Insurers, banks etc.'
        },  {
            category: 'Flat management',
            details: 'Freeholders, landlords, block managing agents etc.'
        }
        ],
        privateOrg: [{
            category: 'The landlord/ Other landlords/employers',
            details: 'To obtain references. This is to ensure suitability for a tenancy in our own legitimate interests. We also provide information to prospective landlords in their legitimate interest to assist them in evaluating suitability for a tenancy/residency. These interests are to ensure that properties are let to reliable tenants/residents. Where the property is subject to selective licensing there is a legal obligation for new landlords to obtain references before they let.'
        }, {
            category: 'Contractors/tradespeople/service suppliers',
            details: 'Assisting in carrying out our responsibilities under the tenancy agreement and for the management of the tenancy and the property for contractual performance. In certain cases, this is also to comply with our legal obligations in relation to housing conditions and health and safety, e.g. gas, electrical and fire alarm maintenance and inspection. We will also provide your contact details to contractors etc., to facilitate access to the property for contract performance. On occasion we will arrange for inspections in our legitimate interests. This is so that we can deal with complaints and pursue/defend claims.'
        }, {
            category: 'Utility companies and service providers, e.g. Broadband',
            details: 'Arranging for utilities/services and establishing liability for payment along with administering their supply in our own legitimate interests and those of the provider. This is to ensure utilities/services are provided and that liability is correctly established. Utility companies also have certain statutory obligations to perform, e.g. metering. In the case of utilities or other service providers if we agree to provide any relevant utility or services as part of the tenancy agreement then these arrangements are made for contractual performance. Otherwise, they are in the legitimate interests of the utilities/service providers to ensure that they can effectively carry out their various activities.'
        }, {
            category: 'Credit reference agencies',
            details: 'We request and consider credit and other referencing relating to deciding on the suitability of tenants and residents for a tenancy. This is in our own legitimate interest to ensure that we let to reliable tenants/residents.'
        }, {
            category: 'Debt collects/tracing agents',
            details: 'To trace you or make a claim in our legitimate interests. These are to enforce our legal rights.'
        }, {
            category: 'Joint tenants/residents',
            details: 'Management of the tenancy and the property for contractual performance. Additionally, in our own legitimate interests and those of joint tenants/residents where there are arrears of rent or other payments due or breaches of the terms of the tenancy agreement as they are either jointly liable for performance or non- performance could adversely impact on their continued residence at the property. The legitimate interests are to protect our own property interests and to enforce our rights.'
        }, {
            category: 'Guarantors',
            details: 'Management of the tenancy and the property for contractual performance. We also inform guarantors of claims and liabilities for contractual performance of the guarantee. It is in our own legitimate interests and those of the guarantor to give and receive information relating to nonperformance of tenancy obligations including nonpayment of rent and other payments. Our interests are to protect our property interests and to enforce our rights and the guarantor’s interests are so that the guarantor is aware of possible liability under the guarantee.'
        }, {
            category: 'Next of kin etc.',
            details: 'To make contact with them in the event of an emergency to protect your vital interests'
        }, {
            category: 'Insurers',
            details: 'To arrange public and other liability insurance and rent insurance and to make claims. This is in our legitimate interests and the insurers legitimate interests. These are making sure that appropriate insurance is arranged and the policies are correctly administered. Under the terms of policies, we are required contractually to provide information to insurers.'
        }, {
            category: 'Banks and lenders',
            details: 'Where we have loans, information regarding tenancies must be provided to arrange and administer loans. This is in our own legitimate interests to finance our business. Contractually they are entitled to your information. In the case of banks information about you can be shared for the purposes of administering rental and other payments for contractual performance. Your information can also be shared for the purposes of preventing and detecting money laundering and fraud. This is in our own legitimate interests and those of the bank in order to detect crime. Contractually we may be required to provide information regarding insurance cover to banks etc. providing us with loans. This is in our legitimate interests to ensure compliance with the loan conditions and in the interests of the bank etc. to see that appropriate insurance cover is in place.'
        }, {
            category: 'Neighbours, other tenants and residents',
            details: 'The management of the tenancy and the property. This includes information relating to complaints including alleged breaches of the tenancy agreement and anti social behaviour, as well as the abandonment of the property. It can include car registration information. This is in our own legitimate interests to protect our property rights and to enforce compliance with the terms of the tenancy. It is also in the legitimate interests of neighbours for their enjoyment of their own properties and to protect their property, interests and rights.'
        }, {
            category: 'Flat freeholders, managing agents etc., where the property is a flat.',
            details: 'The management of the tenancy and the property. Under leases/other contractual arrangements they are entitled to certain information, e.g. who occupies the flat and the terms of such occupation. Further, consents/permissions may be required relating to the tenancy for the carrying out of alterations or works or your information may be relevant to repairs. This is done in our own legitimate interests. These are to ensure we comply with our own contractual obligations. Additionally, there may be complaints involving breach of the terms of these leases/other contractual provisions connected with nuisance, non-compliance with leases/other arrangements or anti social behaviour. Consequent upon this information may be shared in our own legitimate interests or those of the freeholder managing agent etc., concerned. This is to ensure compliance with our own contractual legal obligations and/or for the management of the block.'
        }, {
            category: 'Web sites, portals etc.',
            details: 'Undertaking searches and obtaining publicly available information relevant to your suitability for a tenancy/residency and relating to the management of the tenancy and the property. This is undertaken in accordance with applicable data protection law and guidance and subject to data protection principles. In connection with your application for a tenancy, we may receive information via any online portal involved for contractual performance.'
        }, {
            category: '',
            details: ''
        }
        ],
        publicAuthorities: [{
            category: 'Home Office',
            details: 'Immigration/right to rent checks for the performance of our legal obligations'
        }, {
            category: 'Benefit authority',
            details: 'The administration of benefits such as Universal Credit (by the Department for Work and Pensions) and the housing benefit/local housing allowance by the local authority. This includes applications to them for direct payment of benefit to ourselves. It extends to claims by them for overpayment where we receive direct payments. This is for contractual performance and/or in our legitimate interests to ensure that we collect rent and that amounts properly due to us are received.'
        }, {
            category: 'Deposit body',
            details: 'Under housing legislation we are required to protect any deposit which you pay in connection with the tenancy. We may pay this into a custodial scheme which holds the money or alternatively hold it ourselves in which case it is insured. We have to register the deposit with the deposit body concerned. This is to comply with our legal obligations. During the course of the tenancy it may be necessary to give details of any changes to the deposit body to comply with our legal obligations. On the termination of the tenancy for contractual performance we must arrange for the return of the deposit in whole or in part depending on whether there are any claims on it or not by us. In the event of disputes these can be submitted to adjudication in which case it is necessary for information to be provided to the deposit body concerned (and its adjudicator) in our legitimate interests to pursue or defend claims. This is also contractually required under the rules of the scheme.'
        }, {
            category: 'Educational institutions',
            details: 'Information relating to non- payment of rent, breach of tenancy terms, nuisance or anti social behaviour may be shared with educational institutions where you are studying. Educational institutions can operate complaints schemes in conjunction with landlords of student properties. This can include information about tenancy terminations. This is in our own legitimate interests to protect our property interests and our rights and in the interests of the educational institution concerned to oversee the conduct of their students.'
        }, {
            category: 'Taxation authorities',
            details: 'These are HM Revenue and Customs and (in the case of Council Tax and Council Tax reduction schemes) local authorities, as well as any other public authority having power to levy taxes or charges. We are required by law to make appropriate returns to comply with legislation imposing taxes etc. In the case of Council Tax this is required where statutory notice to that effect is served on us. Otherwise, we share information with the local authority relating to Council Tax in our own legitimate interests to ensure that Council Tax is correctly administered and in the legitimate interests of the local authority to collect information for the same reason.'
        }, {
            category: 'Police/law enforcement agencies',
            details: 'Prevention/detection of crime and anti social behaviour in our and their legitimate interests. This is to protect our property and enforce our rights and to enforce the law.'
        }, {
            category: 'Regulatory authorities',
            details: 'To carry out their functions in their legitimate interests. These are to enforce legal requirements. On occasion, we may be under a legal obligation to provide your information, particularly if a notice to that effect is served on us. This can include an ombudsman or accreditation or similar scheme of which we are a member. It also includes water companies, utility providers who are exercising their functions as statutory undertakers.'
        }, {
            category: 'Courts',
            details: 'The administration of Justice in our legitimate interests. These are to pursue and defend claims.'
        }
        ],
        otherData: [{
            category: 'Professional advisers',
            details: 'Assistance and advice regarding the management of the tenancy and the property for contractual performance. Where we handle your information under some other gateway and a professional adviser assists or advises this will for the same purpose and under the same legal gateway. If it is in the legitimate interests of ourselves or someone else then those interests will be the same. These are to ensure that we act appropriately and properly as well as according to the law.'
        }, {
            category: 'Letting and managing agents',
            details: 'To let or manage the property in our and their legitimate interests. These are so that the tenancy and the property are effectively let and managed. Contractually we are required to provide your information to any letting/managing agent we instruct.'
        }, {
            category: 'Prospective purchaser of the property',
            details: 'In the event of a proposed sale/sale of the property either subject to the tenancy or with vacant possession any prospective purchaser/purchaser will require information about the tenancy and the property. This is in our own legitimate interests and their legitimate interests. This is part of the conveyancing and sales process for both parties. The legitimate interests are to ensure that correct information is provided and received.'
        }
        ],
      }
    }
  }
</script>
<style>
.tab {
  padding-left: 30px;
}
.link-pad {
  padding-top: 40px;
}
</style>
