<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner1.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Landlords</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Introduction</h2>
                        <h5>
                            As experienced Landlords ourselves, we know how vital it is to choose the right Letting Agent to
                            look after your investment. At Burnley Sales & Lettings we pride ourselves on our local property
                            knowledge & customer service to ensure that things run smoothly for you throughout your investment journey.
                        </h5>
                        <h2 class="title">Registering as a Landlord</h2>
                        <h5>
                            1 – Call us to arrange a valuation of your property and discuss your
                            requirements and rental expectations.
                            <br/>
                            <br/>
                            <br/>
                            2 – Once we have agreed a rental figure, we will make sure your property looks
                            its best and take professional quality pictures for advertising. We will also erect
                            an eye catching ‘TO LET’ board outside the property ready for us to start the
                            marketing process.
                            <br/>
                            <br/>
                            <br/>
                            3 – We will market your property and generate multiple enquiries and carry
                            out multiple viewings to ensure your property is let without delay to the best
                            possible candidate.
                            <br/>
                            <br/>
                            <br/>
                            4 – Comprehensive references will be taken up and all relevant paperwork
                            (including tenancy agreements, required certificates) completed on your
                            behalf and provided to you and your tenants as required.
                            <br/>
                            <br/>
                            <br/>
                            5 – Once all the paperwork has been done, we will release the keys to the new
                            tenant and ensure rent is collected and paid to you on a regular basis. We will
                            also deal with any issues on your behalf like maintenance. Leaving you to enjoy
                            the benefits of being a landlord without any hassle or inconvenience.
                            <br/>
                            <br/>
                            <br/>
                        </h5>
                    </div>
                    <div class="col-md-8 ml-auto mr-auto">
                        <card type="background" raised class="card-background-product"
                              style="background-image: url('img/background1.jpg')">
                            <h2 class="card-title text-white">Charges</h2>
                            <p class="text-white">
                                View our Landlord Services and Fees
                            </p>
                            <div class="ml-auto mr-auto">
                                <a href="./content/BSL_Landlord_Fees.pdf" target="_blank">
                                    <n-button type="primary" class="mr-3">
                                        View
                                    </n-button>
                                </a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="subscribe-line subscribe-line-image" style="background-image: url('img/mini-banner.png'); height: 252px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h3 class="title">Now that you know our process</h3>
                        <h4 class="title">Call us now on 01282 476732 to discuss how we can help you on your investment property journey</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, InfoSection,  Badge, Button, Tabs, TabPane } from '@/components';
  export default {
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Button.name]: Button
    },
    metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.burnleysl.co.uk/landlords'},
                {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
                {property: 'og:description', content: ''},
                // Google / Schema.org markup:
                {itemprop: 'description', content: ''},
                {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.burnleysl.co.uk/'}
            ]
        }
    }
  }
</script>
<style>
.text-white {
    color: #fff;
    font-weight: 500;
}
</style>
