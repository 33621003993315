<template>
    <div class="wrapper blog-posts">
        <div v-if="!flag" class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('img/banner12.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">All Properties</h2>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!flag" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="https://www.twitter.com/burnleysl/" target="_blank" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.facebook.com/burnleysl/" target="_blank" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/burnley-sales-lettings-ltd/" target="_blank" class="btn btn-icon btn-lg btn-linkedin btn-round">
                                <i class="fab fa-linkedin"></i>
                            </a>
                            <a href="https://www.instagram.com/burnleysl/" target="_blank" class="btn btn-icon btn-lg btn-instagram btn-round">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section">
            <div class="container">
                <div class="content-center extra-pad" v-if="flag">
                    <h2 class="title text-center">No Content at the moment</h2>
                    <h4 class="text-center">
                        Please check back with us another time
                    </h4>
                </div>
                
                <div class="row">
                    <li v-for="property in propertyList"  :key="property.id" class="col-md-12" style="display: inline;">
                        <div class=""> <!-- v-if="pagination.simple === 2" -->
                            <router-link class="text-black" :to="{ name: 'property', params: {property_id: property.id} }">
                                <!--card type="background" :style="{ backgroundImage: 'url(' + getImg(property.images[0]) + ')' }">
                                    <div class="card-title text-left">
                                        <h4 class="card-title text-white">
                                            <i class="now-ui-icons location_pin"/> {{ property.display }}
                                        </h4>
                                    </div>
                                    <div class="card-footer text-left">
                                        <div class="stats">
                                            <h5>{{property.bedrooms}} Bedroom {{property.type}}</h5>
                                        </div>
                                        <div v-if="property.qualifier.includes('Offers')" class="stats pull-right">
                                            <h3>
                                                {{ property.qualifier }} &#163; {{ property.price }}
                                            </h3>
                                        </div>
                                        <div v-else class="stats pull-right">
                                            <h3>
                                                &#163; {{ property.price }} {{ property.qualifier }}
                                            </h3>
                                        </div>
                                    </div>
                                </card-->
                                <card type="blog" raised>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="card-image">
                                                <img class="img img-raised" :src="getImg(property.images[0])" style="height: 310px; object-fit: cover; object-position: center center;" />
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-8">
                                            <h5 class="card-title">
                                                <i class="now-ui-icons location_pin"/> {{ property.display }}
                                            </h5>

                                            <div v-if="property.qualifier.includes('Offers')" class="stats">
                                                <h3>
                                                    {{ property.status }} | {{ property.qualifier }} &#163; {{ property.price }}
                                                </h3>
                                            </div>
                                            <div v-else class="stats">
                                                <h3>
                                                    {{ property.status }} | &#163; {{ property.price }} {{ property.qualifier }}
                                                </h3>
                                            </div>

                                            <div v-if="property.features" style="margin-bottom: 10px;">
                                                <el-tag
                                                    :key="tag"
                                                    v-for="tag in property.features"
                                                    size="small"
                                                    type="primary"
                                                    :closable="false"
                                                    :close-transition="false"
                                                >
                                                    {{tag}}
                                                </el-tag>
                                            </div>

                                            <div class="stats">
                                                <h5>{{property.bedrooms}} Bedroom {{property.type}}</h5>
                                            </div>
                                            <p>
                                                {{ property.shortDescr }}
                                            </p>
                                        </div>
                                    </div>
                                </card>
                            </router-link>
                            <router-view :key="$route.id"></router-view>
                        </div>
                    </li>
                </div>
                <!--n-pagination type="primary" :per-page="2" :page-count="5" :total="2" v-model="pagination.simple"/-->
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, Pagination, FormGroupInput } from '@/components';
  import { Tag } from 'element-ui';
  //import { NotificationsPlugin } from '../components/NotificationPlugin/index';
  //import { Notifications } from '../components/NotificationPlugin';
  export default {
    name: 'blog-posts',
    bodyClass: 'blog-posts',
    components: {
      Card,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Pagination.name]: Pagination,
      [Tag.name]: Tag,
      //Notifications
    },
    metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.burnleysl.co.uk/all-properties/'},
                {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
                {property: 'og:description', content: 'Browse all our latest properties in Burnley'},
                // Google / Schema.org markup:
                {itemprop: 'description', content: 'Browse all our latest properties in Burnley'},
                {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.burnleysl.co.uk/'}
            ]
        }
    },
    props: { propertyList: Array },
    data() {
      return {
        flag: false,
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        },
        itemsPerPage: 9,
      }
    },
    computed: {
    },
    methods: {
        getImg: function (postImg) {
            if (!postImg) return require("../../public/img/image_placeholder.jpg") // test with actual images stored in a db
            else return postImg // https://i.ibb.co/r5hp1ng/spash-img.jpg or require(postImg)
        }
    },

    mounted() {
        if(this.propertyList.length === 0) {
            this.flag = true
        }
    }
  }
</script>
<style scoped>
h3 {
    margin-bottom: 10px;
}
.extra-pad {
    padding-top: 70px;
}

.text-black {
    color: #000;
}

.button-container {
    text-align: center;
    margin-top: -112px;
    position: relative;
    z-index: 100;
}
</style>
