import api from './api'

let counter = 0

export default {
  getAll () {
    return api().get('api/properties')
  },

  getDisplayProps () {
    return api().get('api/properties/display')
  },

  getProperty (details) {
    return api().post('api/properties/details', details)
  },

  getToken () {
    if (counter === 0) {
      counter++
      return api().get('api/properties/token')
    } else {
      return ''
    }
  }
}
