<template>
  <div class="wrapper">
    <div class="section pad">
      <div class="container">
        <div class="row">
            <div :class="mainCard" style="padding-left: 0px;padding-right: 0px;padding-top: 0px;">
                <b-carousel
                    id="carousel-1"
                    :interval="4000"
                    controls
                    indicators
                    background="#ababab"
                    img-width="1440"
                    img-height="1080"
                    style="text-shadow: 1px 1px 2px #333; backround-size:cover;"
                >
                    <b-carousel-slide v-for="item in property.images" :key="item.id">
                        <template #img>
                            <img
                              class="d-block img-fluid w-100"
                              :src="item.image"
                              alt="Slider image"
                            >
                        </template>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <div :class="sideCard" style="padding-left: 0px; padding-right: 0px;padding-bottom: 0px;">
                <card type="blog" raised :style="fixHeight" class="rounded-0">
                    <div class="row" style="margin-bottom:5%;margin-top:0px;">
                        <div class="col-md-12" style="margin-bottom:0%;margin-top:0px;">
                          <div class="info info-hover" style="margin-bottom:0%;margin-top:0px;">
                              <div class="icon icon-primary">
                                  <i class="now-ui-icons location_pin"/>
                              </div>
                              <h4 class="card-title">
                                  {{property.bedrooms}} Bedroom {{property.type}}
                              </h4>
                              <p class="category">{{ property.display }}</p>

                              <br>

                              <div class="stats">
                                  <i class="fas fa-sink fa-lg" style="color: #630530;"></i>  {{ property.bathrooms }} &emsp; <i class="fas fa-bed fa-lg" style="color: #630530;"></i> {{ property.bedrooms }} &emsp; <i class="fas fa-couch fa-lg" style="color: #630530;"/> {{ property.receptions }}
                              </div>
                          </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="col-md-12">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="now-ui-icons ui-1_email-85"></i> &nbsp;&nbsp;&nbsp;&nbsp; <i class="now-ui-icons tech_mobile"></i>
                            </div>
                            <h5 class="info-title">Contact us to arrange a viewing</h5>
                            <p class="info-title">
                                01282 476 732<br>
                                <a href="mailto:lettings@burnleysl.co.uk">lettings@burnleysl.co.uk</a><br>
                                <a href="mailto:sales@burnleysl.co.uk">sales@burnleysl.co.uk</a>
                            </p>
                        </div>
                    </div>
                </card>
            </div>
        </div>
        <div class="row pad">
          <div class="col-md-8 ml-auto mr-auto">
            <!--h2 class="title">{{property.address}}</h2>
            <h3 class="title">{{property.bedrooms}} Bedroom {{property.type}}</h3>
            <h5 class="category">{{ property.display }}</h5-->
            <h2 class="main-price" v-if="property.qualifier.includes('Per')">{{ property.status }} | &#163; {{ property.price }} {{ property.qualifier }}</h2>
            <h2 class="main-price" v-else>{{ property.status }} | {{ property.qualifier }} &#163; {{ Number(property.price).toLocaleString('en-GB', {maximumFractionDigits:0}) }}</h2>
            <div v-if="property.features">
              <h3 class="title">Key Features</h3>
              <hr>
              <h5>
                <ul>
                  <li v-for="(item, index) in property.features">{{ item }}</li>
                </ul>
              </h5>
            </div>
            <a :href="property.pdf" target="_blank">
                <n-button type="primary" class="mr-3">
                  View Brochure
                </n-button>
              </a>
            

            <br>

            <h3 class="title">Property Description</h3>
            <hr>
            <h5 v-html="property.description"></h5>
          </div>
          <div class="col-md-4">
            <div style="height: 500px;">
                <div>
                  <h3 class="title">Map</h3>
                </div>
                <l-map
                  v-if="showMap"
                  :zoom="zoom"
                  :center="center"
                  :options="mapOptions"
                  style="height: 80%"
                  @update:center="centerUpdate"
                  @update:zoom="zoomUpdate"
                >
                <l-tile-layer
                    :url="url"
                    :attribution="attribution"
                />
                <l-marker :lat-lng="withPopup">
                    <l-popup>
                    </l-popup>
                </l-marker>
                <l-marker :lat-lng="withTooltip">
                    <l-tooltip :options="{ permanent: true, interactive: true }">
                    <div @click="innerClick">
                        {{ property.display }}
                    </div>
                    </l-tooltip>
                </l-marker>
                </l-map>
            </div>
            <div>
              <h3 class="title">EE Rating</h3>
              <hr>
              <img v-if="property.rating" :src="property.rating" alt="Energy Efficiency and Environmental Impact Rating">
              <p v-else>No rating available. Check the Property Description.</p>
            </div>
          </div>
          <!--div class="col-md-4 ml-auto">
              <card type="background" raised class="card-background-product"
                    style="background-image: url('img/background2.jpg')">
                  <h2 class="card-title text-white">Complaints Procedure</h2>
                  <p class="text-white">
                      If you have any complaints, follow our procedure
                  </p>
                  <div class="ml-auto mr-auto">
                      <a href="./content/Complaints_Proc.pdf" target="_blank">
                          <n-button type="primary" class="">
                              View
                          </n-button>
                      </a>
                  </div>
              </card>
          </div-->
        </div>

        <!--div class="row pad">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="title">Property Description</h2>
            <h5 v-html="property.description"></h5>

            <div class="row">
              <a :href="property.pdf" target="_blank">
                <n-button type="primary" class="mr-3">
                  View Brochure
                </n-button>
              </a>
            </div>
          </div>
          
          <div class="col-md-4" style="padding-top: 50px;">
              <card type="background" raised class="card-background-product"
                    style="background-image: url('img/background2.jpg')">
                  <h2 class="card-title text-white">Complaints Procedure</h2>
                  <p class="text-white">
                      If you have any complaints, follow our procedure
                  </p>
                  <div class="ml-auto mr-auto">
                      <a href="./content/Complaints_Proc.pdf" target="_blank">
                          <n-button type="primary" class="">
                              View
                          </n-button>
                      </a>
                  </div>
              </card>
          </div>
        </div-->
        <div class="row pad">
          <div class="mr-auto col-sm-12 col-md-8">
            <h3 class="title">Room Measurements</h3>
            <hr>
            <card class="card-plain">
              <div class="row">
                <el-table stripe :data="property.measurements">
                    <el-table-column
                      min-width="150"
                      align="left"
                      prop="name"
                      label="Room">
                    </el-table-column>
                    <el-table-column
                      min-width="200"
                      prop="dimensions"
                      align="left"
                      label="Dimensions">
                    </el-table-column>
                </el-table>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, Collapse, CollapseItem } from '@/components';
  import { Table, TableColumn, Select, Option, Tooltip } from 'element-ui'

  import { latLng, Icon } from "leaflet";
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

  export default {
    name: 'product-page',
    bodyClass: 'product-page',
    components: {
      Card,
      Collapse,
      CollapseItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LTooltip
    },
    props: { property: Object },
    data() {
      return {
        screenWidth: 0,
        mainCard: "col-md-8",
        sideCard: "col-md-4 rounded-0",
        filters: {
          color: '',
          size: ''
        },
        year: new Date().getFullYear(),
        zoom: 15,
        center: latLng(this.property.latitude, this.property.longitude),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(this.property.latitude, this.property.longitude),
        withTooltip: latLng(this.property.latitude, this.property.longitude),
        currentZoom: 17,
        currentCenter: latLng(this.property.latitude, this.property.longitude),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5
        },
        showMap: true
      }
    },
    computed: {
      fixHeight() {
        if(this.property.id === '32174586') {
          return ""
        } else {
          return "height: 100%;padding: 0px"
        }
      }
    },
    methods: {
      classA() {
          if(window.innerWidth > 1247) {
              return "col-md-4 col-lg-4 rounded-0"
          } else {
              return "col-md-12 rounded-0"
          }
      },
      onScreenResize() {
          window.addEventListener("resize", () => {
              this.updateScreenWidth();
          });
      },
      updateScreenWidth() {
          this.screenWidth = window.innerWidth
          if(this.screenWidth < 1500) { this.sideCard = "col-md-12 rounded-0"; this.mainCard = "col-md-12" } else { this.sideCard = "col-md-4 rounded-0"; this.mainCard = "col-md-8" }
      },
      zoomUpdate(zoom) {
        this.currentZoom = zoom;
      },
      centerUpdate(center) {
        this.currentCenter = center;
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      innerClick() {
        alert("Click!");
      }
    },
    mounted () {
      this.updateScreenWidth();
      this.onScreenResize();

      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      });
    }

    /*mounted () {
        const latitude = Number(this.property.latitude)
        const longitude = Number(this.property.longitude)

        let map1 = new Map({
            target: 'map',
            layers: [
            new TileLayer({
                source: new OSM()
            })
            ],
            view: new View({
            center: transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857'),
            zoom: 17
            })
        })

        var vectorLayer = new VectorLayer({
            source: new VectorSource({
            features: [new Feature({
                    geometry: new Point(transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')),
                    // geometry: new Point(transform([parseFloat(-2.2599791), parseFloat(53.7843879)], 'EPSG:4326', 'EPSG:3857')),
                })]
            }),
            style: new Style({
            image: new Icon({
                anchor: [0.5, 0.5],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg"
            })
            })
        })

        map1.addLayer(vectorLayer);
    }*/
  }
</script>
<style>
#pad {
  padding-top: 10px;
}
</style>
