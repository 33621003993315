<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner2.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Tenants</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Introduction</h2>
                        <h5>
                            We understand that renting a property is seen as a stressful thing to do,
                            so that’s why we’re on hand to help you through every step – from the moment you first register with us!
                            We pride ourselves on our property standards and also work with trusted local contractors to
                            ensure any maintenance issue can be dealt with efficiently.
                        </h5>
                        <h2 class="title">Registering as a Tenant</h2>
                        <h5>
                            1 – Call us to register your contact details and we will discuss the size of
                            property, type of property and how much you are wanting to pay in rent.
                            <br/>
                            <br/>
                            <br/>
                            2 – We will then source suitable properties and arrange with you to view them
                            at a convenient time.
                            <br/>
                            <br/>
                            <br/>
                            3 – Once you have found your new home we will take you through our easy
                            letting process of completing a simple application and taking up references on
                            your behalf.
                            <br/>
                            <br/>
                            <br/>
                            4 – Once everything is satisfactory, we will arrange a convenient time for you
                            to sign your tenancy and collect your keys.
                            <br/>
                            <br/>
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="subscribe-line subscribe-line-image" style="background-image: url('img/mini-banner.png'); height: 252px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h3 class="title">Now that you know our process</h3>
                        <h4 class="title">Call us now on 01282 476732 to discuss your property aspirations so we can help you find your next home</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {}
</script>
