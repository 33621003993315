import Vue from "vue";
import Router from "vue-router";
import Navbar from "./layout/Navbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import AccFooter from "./layout/AccFooter.vue";
import Home from "./pages/LandingPage.vue";
import Buyers from "./pages/Buyers.vue";
import Sellers from "./pages/Sellers.vue";
import Landlords from "./pages/Landlords.vue";
import Finance from "./pages/Finance.vue";
import Tenants from "./pages/Tenants.vue";
import About from "./pages/About.vue";
import Privacy from "./pages/Privacy.vue";
//import UC from "./pages/UnderConstruction.vue";
import Maintenance from "./pages/Maintenance.vue";
import Accreditations from "./pages/Accreditations.vue";
import Contact from "./pages/Contact.vue";

/*import Example from "./pages/example.vue"
import ExampleToo from "./pages/example2.vue"*/

// Property pages
import Property from "./pages/Property.vue";
import Sales from "./pages/PropertyListSales.vue";
import Lettings from "./pages/PropertyListLet.vue";
import AllProps from "./pages/PropertyListMix.vue";

// Blog pages
/*import Blogs from "./pages/Blogs.vue"
import BlogPost from "./pages/BlogPost.vue"*/

// API calls
import properties from './services/Properties'
//import blog from './services/Blog'

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      components: {default: Home, header: Navbar, footer: AccFooter},
      props: {footer: {type: 'default'},
        default: { propertyList: [] },
        header: {colorOnScroll: '#'}
      },
      async beforeEnter (to, from, next) {
        try {
          await properties.getToken()
          const response = await properties.getDisplayProps()
          to.matched[0].props.default.propertyList = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          to.matched[0].props.default.propertyList = []
          next()
        }
      }
    },
    /*{
      path: "/post/:post_id", // post subtitle
      name: 'post',
      components: {default: BlogPost, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, default: { post: {}, relatedPosts: [] } },
      async beforeEnter (to, from, next) {
        try {
          const response1 = await blog.getOne({ path: to.params.post_id })
          const response2 = await blog.getRelated({ path: to.params.post_id })
          
          to.matched[0].props.default.post = response1.data
          to.matched[0].props.default.relatedPosts = response2.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next()
        }
      }
    },
    {
      path: "/blog",
      name: 'blogs',
      components: {default: Blogs, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, default: { posts: [] }},
      async beforeEnter (to, from, next) {
        try {
          const response = await blog.getAll()
          to.matched[0].props.default.posts = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next()
        }
      }
    },*/
    {
      path: "/sales",
      components: {default: Sales, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'},
        default: { propertyList: [] },
        header: {colorOnScroll: '#630530'}
      },
      async beforeEnter (to, from, next) {
        try {
          await properties.getToken()
          const response = await properties.getAll()
          to.matched[0].props.default.propertyList = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next()
        }
      }
    },
    {
      path: "/lettings",
      components: {default: Lettings, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'},
        default: { propertyList: [] },
        header: {colorOnScroll: '#630530'}
      },
      async beforeEnter (to, from, next) {
        try {
          await properties.getToken()
          const response = await properties.getAll()
          to.matched[0].props.default.propertyList = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next()
        }
      }
    },
    {
      path: "/all-properties",
      components: {default: AllProps, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'},
        default: { propertyList: [] },
        header: {colorOnScroll: '#630530'}
      },
      async beforeEnter (to, from, next) {
        try {
          await properties.getToken()
          const response = await properties.getAll()
          to.matched[0].props.default.propertyList = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next({ name: 'home' })
        }
      }
    },
    {
      path: "/property/:property_id",
      name: "property",
      components: {default: Property, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, default: { property: {} }, header: {colorOnScroll: '#630530'}},
      async beforeEnter (to, from, next) {
        try {
          await properties.getToken()
          const response = await properties.getProperty({id: to.params.property_id})
          to.matched[0].props.default.property = response.data
          next()
        } catch (err) {
          // backend app is likely offline
          console.log(err)
          next({ name: 'home' })
        }
      }
    },
    {
      path: "/buyers",
      name: "buyers",
      components: {default: Buyers, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    /*{
      path: "/eg",
      name: "example",
      components: {default: Example, header: Navbar, footer: MainFooter},
      props: { footer: {type: 'default'} }
    },
    {
      path: "/eg2",
      name: "example2",
      components: {default: ExampleToo, header: Navbar, footer: MainFooter},
      props: { footer: {type: 'default'} }
    },*/
    {
      path: "/sellers",
      name: "sellers",
      components: {default: Sellers, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/landlords",
      name: "landlords",
      components: {default: Landlords, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/tenants",
      name: "tenants",
      components: {default: Tenants, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/finance",
      name: "finance",
      components: {default: Finance, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/about-us",
      name: "about",
      components: {default: About, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/maintenance",
      name: "maintenance",
      components: {default: Maintenance, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/privacy-policy",
      name: "privacy",
      components: {default: Privacy, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    /*{
      path: "/under-contruction",
      name: "under-contruction",
      components: {default: UC, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },*/
    {
      path: "/accreditations",
      name: "accreditations",
      components: {default: Accreditations, header: Navbar, footer: AccFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: {default: Contact, header: Navbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: '#630530'}}
    },
    {
      path: "*",
      redirect: { name: 'home' }
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
