<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner5.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Financial Services & Investments</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Introduction</h2>
                        <h5>
                          Navigating through the financial market is never easy & straight forward; so that’s
                          why we work alongside specialist financial experts who can provide tailor-made solutions
                          using whole of market products at a competitive price. From Landlord insurance to mortgages,
                          it is always worth checking that you are getting the best deal to make sure your money goes further.
                        </h5>
                        <h2 class="title">Services Provided</h2>
                        <h5>
                            <ul>
                              <li>Mortgages</li>
                              <li>Personal Protection – Life cover, critical illness & income protection etc</li>
                              <li>Insurance for business, landlords and households</li>
                              <li>CheckMyFile – credit report</li>
                              <li>Property Investments</li>
                              <li>Other Investments</li>
                              <li>Accounting – All aspects of company accounting and individual self-assessment</li>
                            </ul>
                            <br/>
                            Our specialist partners provide tailor-made & whole of market product availability at competitive rates.
                        </h5>

                        <h2 class="title">AKORN</h2>
                        <h5>
                            We're working in conjuction with AKORN Financial Advice who provide additional services.
                            The following is a message from AKORN: <br/><br/>
                            At AKORN we love helping our clients to secure the house of their dreams!<br/><br/>

                            <b>Why we do what we do?</b><br/>
                            Because we strongly believe in making a difference to our clients lives,
                            our local community and within our industry.<br/><br/>
                            
                            <b>What we do?</b><br/>
                            We love doing things differently by improving the way people engage
                            and access the mortgage advice they need, at a time they need it.<br/><br/>
                            
                            <b>How we do it?</b><br/>
                            By making our services accessible, affordable and simple to use……for everyone.

                            <br/><br/>
                            And on top of all of that (and some would say more importantly) we love
                            providing peace of mind and a GREAT client experience.
                            
                            <br/><br/>
                            As a mortgage is secured against your home, it could be repossessed if
                            you do not keep up the mortgage repayments.
                        </h5>
                        <a href="https://akornfa.co.uk/" target=_blank><img v-lazy="'img/AKORN.png'" alt="AKORN logo"></a>
                    </div>
                </div>
            </div>
        </div>
            <!--to-the-top></to-the-top-->

        <div class="subscribe-line subscribe-line-image" style="background-image: url('img/mini-banner.png'); height: 252px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h4 class="title">Call AKORN now on 01254 584330 for a no obligation 30 minute call with one of their advisers</h4>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {}
</script>
