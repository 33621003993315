<template>
    <div class="wrapper contact-page">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner11.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Contact Us</h1>
            </div>
        </div>

        <div class="main">
            <div class="contact-content">
                <div class="text-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto">
                                <h2 class="title">How to reach us</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="info info-hover">
                                    <div class="icon icon-primary">
                                        <i class="now-ui-icons location_pin"></i>
                                    </div>
                                    <h4 class="info-title">Find us at our office</h4>
                                    <h5 class="info-title"> 78 Coal Clough Lane
                                        <br> Burnley
                                        <br> Lancashire
                                        <br> BB11 4NW
                                    </h5>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="info info-hover">
                                    <div class="icon icon-primary">
                                        <i class="now-ui-icons tech_mobile"></i>
                                    </div>
                                    <h4 class="info-title">Give us a ring</h4>
                                    <h5 class="info-title">
                                        01282 476 732
                                        <br> Mon - Fri, 09:00-17:30
                                    </h5>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="info info-hover">
                                    <div class="icon icon-primary">
                                        <i class="now-ui-icons ui-1_email-85"></i>
                                    </div>
                                    <h4 class="info-title">Send us an email</h4>
                                    <h5 class="info-title">
                                        <a href="mailto:lettings@burnleysl.co.uk">lettings@burnleysl.co.uk</a><br>
                                        <a href="mailto:sales@burnleysl.co.uk">sales@burnleysl.co.uk</a>
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8 ml-auto mr-auto" style="padding-top: 50px;">
                          <card type="background" raised class="card-background-product"
                                style="background-image: url('img/background2.jpg')">
                              <h2 class="card-title text-white">Complaints Procedure</h2>
                              <p class="text-white">
                                  If you have any complaints, follow our procedure
                              </p>
                              <div class="ml-auto mr-auto">
                                  <a href="./content/Complaints_Proc.pdf" target="_blank">
                                      <n-button type="primary" class="">
                                          View
                                      </n-button>
                                  </a>
                              </div>
                          </card>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div id="map" class="col-md-12 ml-auto mr-auto"></div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, InfoSection, FormGroupInput } from '@/components';

  import 'ol/ol.css';
  import {Map, View} from 'ol';
  // import ol from 'ol';
  import TileLayer from 'ol/layer/Tile';
  import OSM from 'ol/source/OSM';
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import {Style, Icon} from 'ol/style'
  import Feature from 'ol/Feature'
  import Point from 'ol/geom/Point'
  import {transform} from 'ol/proj.js'; // help get correct starting coordinates


  export default {
    components: {
      Card,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
    },
    metaInfo() {
        return {
          meta: [
            // OpenGraph data
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: 'https://www.burnleysl.co.uk/contact-us/'},
            {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
            {property: 'og:description', content: 'Give us a ring at 01282 476 732 from 10am - 5pm between Monday to Friday'},
            // Google / Schema.org markup:
            {itemprop: 'description', content: 'Give us a ring at 01282 476 732 from 10am - 5pm between Monday to Friday'},
            {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
          ],
          link: [
            {rel: 'canonical', href: 'https://www.burnleysl.co.uk/contact-us/'}
          ]
        }
    },
    mounted() {
      let map1 = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: transform([-2.258328752426875, 53.784468348486904], 'EPSG:4326', 'EPSG:3857'),
          zoom: 17
        })
      })

      var vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [new Feature({
                geometry: new Point(transform([-2.258328752426875, 53.784468348486904], 'EPSG:4326', 'EPSG:3857')),
                // geometry: new Point(transform([parseFloat(-2.2599791), parseFloat(53.7843879)], 'EPSG:4326', 'EPSG:3857')),
            })]
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg"
          })
        })
      })

      map1.addLayer(vectorLayer);
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          phone: ''
        },
        center: {
          lat: 53.7845316,
          lng: -2.2585766
        }
      }
    }
  }
</script>
<style>
  #map {
    height: 300px;
  }
</style>
